import * as React from "react"
import { Footer } from "../components/footer"
import "../components/globale.scss" //orribile, funziona globalmente
import Header2 from "../components/header2"
import { Mappa2 } from "../components/mappa2"
import * as styles from '../components/index.module.scss'
import { StdHelmet } from "../components/StdHelmet"

function Render() {
	return <div className={styles.contenuto}>
		<StdHelmet
			canonical="https://www.antoniogallenga.it"
			title="Antonio Gallenga"
			description="Scopri insieme a noi la rocambolesca vita di Antonio Gallenga: giornalista, scrittore e patriota italiano"
		/>

		<Header2 lang="it" />

		<Mappa2 lang="it" />
		<Footer lang="it" floating={true} />
	</div>
}


export default Render